.container {
  position: relative;
  overflow: hidden;
}

.container::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 4em;
  background: linear-gradient(transparent, #fff);
}

.expanded.container::after {
  background: none;
}
