.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  border-radius: 5px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-descriptions-item-label {
  font-weight: bold;
}

/* Padding */
.style_box__DV_vb.style_padding-xl__ef_Y8 {
  padding: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-lg__V1Y44 {
  padding: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-md__N7YN3 {
  padding: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-sm__MurfW {
  padding: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-xs__KjwD3 {
  padding: var(--ant-padding-xs);
}

/* Padding Top */
.style_box__DV_vb.style_padding-top-xl__Hiekt {
  padding-top: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-top-lg__v5D_2 {
  padding-top: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-top-md__ZiYdk {
  padding-top: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-top-sm__BJwGS {
  padding-top: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-top-xs__D0T9B {
  padding-top: var(--ant-padding-xs);
}

/* Padding Right */
.style_box__DV_vb.style_padding-right-xl__EIssu {
  padding-right: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-right-lg__UUmot {
  padding-right: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-right-md__DbMGX {
  padding-right: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-right-sm__YMK0l {
  padding-right: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-right-xs__BElPY {
  padding-right: var(--ant-padding-xs);
}

/* Padding Bottom */
.style_box__DV_vb.style_padding-bottom-xl__sw68X {
  padding-bottom: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-bottom-lg__StZMe {
  padding-bottom: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-bottom-md__AOzzR {
  padding-bottom: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-bottom-sm__Rw88F {
  padding-bottom: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-bottom-xs__wC2Wd {
  padding-bottom: var(--ant-padding-xs);
}

/* Padding Left */
.style_box__DV_vb.style_padding-left-xl__H4ryI {
  padding-left: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-left-lg__uI_1y {
  padding-left: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-left-md__8eszA {
  padding-left: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-left-sm__iTMtH {
  padding-left: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-left-xs__jbpf2 {
  padding-left: var(--ant-padding-xs);
}

/* Padding Vertical */
.style_box__DV_vb.style_padding-vertical-xl__GHMrx {
  padding-top: var(--ant-padding-xl);
  padding-bottom: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-vertical-lg__zBQCf {
  padding-top: var(--ant-padding-lg);
  padding-bottom: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-vertical-md__zigw_ {
  padding-top: var(--ant-padding-md);
  padding-bottom: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-vertical-sm__A2uXc {
  padding-top: var(--ant-padding-sm);
  padding-bottom: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-vertical-xs__1L1To {
  padding-top: var(--ant-padding-xs);
  padding-bottom: var(--ant-padding-xs);
}

/* Padding Horizontal */
.style_box__DV_vb.style_padding-horizontal-xl__qaILM {
  padding-right: var(--ant-padding-xl);
  padding-left: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-horizontal-lg__gaZH4 {
  padding-right: var(--ant-padding-lg);
  padding-left: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-horizontal-md__BHFfG {
  padding-right: var(--ant-padding-md);
  padding-left: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-horizontal-sm__FH9u4 {
  padding-right: var(--ant-padding-sm);
  padding-left: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-horizontal-xs__J8OrK {
  padding-right: var(--ant-padding-xs);
  padding-left: var(--ant-padding-xs);
}

/* 
 * Export breakpoints values.
 *
 * We need to export breakpoint values this way because CSS variables don't work in media query declarations.
 * 
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties#
 * @see https://github.com/css-modules/css-modules/issues/75
*/

.style_container__s9_0y {
  margin: 0 auto;
  width: 100%;
}

.style_container__s9_0y.style_xs__KWRsn {
  max-width: 480px;
}

.style_container__s9_0y.style_sm___rpxe {
  max-width: 576px;
}

.style_container__s9_0y.style_md__Cml1F {
  max-width: 768px;
}

.style_container__s9_0y.style_lg__rVlyI {
  max-width: 1200px;
}

.style_container__s9_0y.style_xl__EKU7t {
  max-width: 1600px;
}

.style_logo__XEHgy {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.style_grid__R0osP {
  display: grid;
  grid-gap: var(--ant-padding-md);
  gap: var(--ant-padding-md);
}

@media (min-width: 992px) {
  .style_grid__R0osP {
    grid-template: auto / repeat(2, 1fr);
  }

  .style_grid__R0osP > :only-child {
    grid-column: span 2;
  }

  /**
   * nth-last-child counts backwards from the last element, that way the first element in reading order is not always odd.
   *
   * 1. nth-last-child 5 (odd) (first-child)
   * 2. nth-last-child 4
   * 3. nth-last-child 3 (odd)
   * 4. nth-last-child 2
   * 5. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 4       (first-child)
   * 2. nth-last-child 3 (odd)
   * 3. nth-last-child 2
   * 4. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 3 (odd) (first-child)
   * 2. nth-last-child 2
   * 3. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 2       (first-child)
   * 2. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 1 (odd) (first-child) (only-child)
   */
  .style_grid__R0osP > :nth-last-child(odd):first-child {
    grid-row: span 2;
    height: 100%;
  }
}

.style_media__bksIL {
  background: white;
  overflow: hidden;
  padding-top: calc(100% / (16 / 9));
  position: relative;
}

.style_media__bksIL * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .style_media__bksIL * {
    border-radius: var(--ant-border-radius);
  }
}

.style_media__bksIL img {
  object-fit: cover;
}

.style_container__Sr4WP {
  position: relative;
  overflow: hidden;
}

.style_container__Sr4WP::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 4em;
  background: linear-gradient(transparent, #fff);
}

.style_expanded__JJnhy.style_container__Sr4WP::after {
  background: none;
}

.ant-affix {
  position: fixed;
  z-index: 10;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.style_layout__OcnEb {
  /**
   * On mobile devices we had a problem where 100vh will be larger than the visible
   * height when the URL bar is shown.
   * The useSetFullHeight hook solution is not compatible with this case, because
   * this layout must be able to be more than 100% height.
   * As in this layout the content will hardly be smaller than the viewport, this
   * is not a problem for now, but if that happens it will need to be fixed in the future.
   */
  min-height: 100vh;
}

.style_header__MZ8wE {
  border-bottom: var(--ant-line-width) var(--ant-line-type)
    var(--ant-color-border-secondary);
  padding: 0 var(--ant-padding-md);
}

.style_footer__y2WW7 {
  /* Division line between Footer (white background) and the content well (dim background). */
  border-top: var(--ant-line-width) var(--ant-line-type)
    var(--ant-color-border-secondary);
  line-height: var(--layout-footer-height);
  padding: var(--ant-padding-lg) var(--ant-padding-md);
}

.style_brand__1omIJ {
  display: block;
  margin-bottom: -10px; /* Because of the descending "y". */
}

.style_brand__1omIJ.style_collapsed__k50IJ {
  margin-bottom: 0;
}

.style_brand__1omIJ.style_middle__fTkVE {
  height: 36px;
}

.style_brand__1omIJ.style_large__IoaxL {
  height: 64px;
}

.style_brand__1omIJ svg {
  height: 100%;
}

.style_social-links__6fDR1 {
  background: hsl(210deg 8% 35% / 0.2);
}

.style_social-links__6fDR1 a {
  font-size: 22px;
  color: inherit;
}

.style_social-links__6fDR1 .style_flex-content__jrohv {
  flex-direction: column;
}

@media (min-width: 992px - 1px) {
  .style_social-links__6fDR1 .style_flex-content__jrohv {
    flex-direction: row;
  }
}

.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li button::after {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  content: '';
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 0;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel .slick-dots li button,
.ant-carousel .slick-dots li.slick-active button {
  background: rgba(0, 0, 0, 0.85);
}

.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.style_testimonials__4IQMv {
  background: url("/images/testimonials-background.jpg") no-repeat center /
    cover;
}

