@value screen-xs, screen-sm, screen-md, screen-xl, screen-xxl from "~/src/styles/breakpoints.module.css";

.container {
  margin: 0 auto;
  width: 100%;
}

.container.xs {
  max-width: screen-xs;
}

.container.sm {
  max-width: screen-sm;
}

.container.md {
  max-width: screen-md;
}

.container.lg {
  max-width: screen-xl;
}

.container.xl {
  max-width: screen-xxl;
}
