@value screen-lg, screen-md from "~/src/styles/breakpoints.module.css";

.grid {
  display: grid;
  gap: var(--ant-padding-md);
}

@media (min-width: screen-lg) {
  .grid {
    grid-template: auto / repeat(2, 1fr);
  }

  .grid > :only-child {
    grid-column: span 2;
  }

  /**
   * nth-last-child counts backwards from the last element, that way the first element in reading order is not always odd.
   *
   * 1. nth-last-child 5 (odd) (first-child)
   * 2. nth-last-child 4
   * 3. nth-last-child 3 (odd)
   * 4. nth-last-child 2
   * 5. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 4       (first-child)
   * 2. nth-last-child 3 (odd)
   * 3. nth-last-child 2
   * 4. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 3 (odd) (first-child)
   * 2. nth-last-child 2
   * 3. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 2       (first-child)
   * 2. nth-last-child 1 (odd)
   *
   * 1. nth-last-child 1 (odd) (first-child) (only-child)
   */
  .grid > :nth-last-child(odd):first-child {
    grid-row: span 2;
    height: 100%;
  }
}

.media {
  background: white;
  overflow: hidden;
  padding-top: calc(100% / (16 / 9));
  position: relative;
}

.media * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (min-width: screen-md) {
  .media * {
    border-radius: var(--ant-border-radius);
  }
}

.media img {
  object-fit: cover;
}
