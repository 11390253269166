@value screen-lg from "~/src/styles/breakpoints.module.css";

.social-links {
  background: hsl(210deg 8% 35% / 0.2);
}

.social-links a {
  font-size: 22px;
  color: inherit;
}

.social-links .flex-content {
  flex-direction: column;
}

@media (min-width: screen-lg - 1px) {
  .social-links .flex-content {
    flex-direction: row;
  }
}
