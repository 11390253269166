.brand {
  display: block;
  margin-bottom: -10px; /* Because of the descending "y". */
}

.brand.collapsed {
  margin-bottom: 0;
}

.brand.middle {
  height: 36px;
}

.brand.large {
  height: 64px;
}

.brand svg {
  height: 100%;
}
