/* Padding */
.box.padding-xl {
  padding: var(--ant-padding-xl);
}

.box.padding-lg {
  padding: var(--ant-padding-lg);
}

.box.padding-md {
  padding: var(--ant-padding-md);
}

.box.padding-sm {
  padding: var(--ant-padding-sm);
}

.box.padding-xs {
  padding: var(--ant-padding-xs);
}

/* Padding Top */
.box.padding-top-xl {
  padding-top: var(--ant-padding-xl);
}

.box.padding-top-lg {
  padding-top: var(--ant-padding-lg);
}

.box.padding-top-md {
  padding-top: var(--ant-padding-md);
}

.box.padding-top-sm {
  padding-top: var(--ant-padding-sm);
}

.box.padding-top-xs {
  padding-top: var(--ant-padding-xs);
}

/* Padding Right */
.box.padding-right-xl {
  padding-right: var(--ant-padding-xl);
}

.box.padding-right-lg {
  padding-right: var(--ant-padding-lg);
}

.box.padding-right-md {
  padding-right: var(--ant-padding-md);
}

.box.padding-right-sm {
  padding-right: var(--ant-padding-sm);
}

.box.padding-right-xs {
  padding-right: var(--ant-padding-xs);
}

/* Padding Bottom */
.box.padding-bottom-xl {
  padding-bottom: var(--ant-padding-xl);
}

.box.padding-bottom-lg {
  padding-bottom: var(--ant-padding-lg);
}

.box.padding-bottom-md {
  padding-bottom: var(--ant-padding-md);
}

.box.padding-bottom-sm {
  padding-bottom: var(--ant-padding-sm);
}

.box.padding-bottom-xs {
  padding-bottom: var(--ant-padding-xs);
}

/* Padding Left */
.box.padding-left-xl {
  padding-left: var(--ant-padding-xl);
}

.box.padding-left-lg {
  padding-left: var(--ant-padding-lg);
}

.box.padding-left-md {
  padding-left: var(--ant-padding-md);
}

.box.padding-left-sm {
  padding-left: var(--ant-padding-sm);
}

.box.padding-left-xs {
  padding-left: var(--ant-padding-xs);
}

/* Padding Vertical */
.box.padding-vertical-xl {
  padding-top: var(--ant-padding-xl);
  padding-bottom: var(--ant-padding-xl);
}

.box.padding-vertical-lg {
  padding-top: var(--ant-padding-lg);
  padding-bottom: var(--ant-padding-lg);
}

.box.padding-vertical-md {
  padding-top: var(--ant-padding-md);
  padding-bottom: var(--ant-padding-md);
}

.box.padding-vertical-sm {
  padding-top: var(--ant-padding-sm);
  padding-bottom: var(--ant-padding-sm);
}

.box.padding-vertical-xs {
  padding-top: var(--ant-padding-xs);
  padding-bottom: var(--ant-padding-xs);
}

/* Padding Horizontal */
.box.padding-horizontal-xl {
  padding-right: var(--ant-padding-xl);
  padding-left: var(--ant-padding-xl);
}

.box.padding-horizontal-lg {
  padding-right: var(--ant-padding-lg);
  padding-left: var(--ant-padding-lg);
}

.box.padding-horizontal-md {
  padding-right: var(--ant-padding-md);
  padding-left: var(--ant-padding-md);
}

.box.padding-horizontal-sm {
  padding-right: var(--ant-padding-sm);
  padding-left: var(--ant-padding-sm);
}

.box.padding-horizontal-xs {
  padding-right: var(--ant-padding-xs);
  padding-left: var(--ant-padding-xs);
}
