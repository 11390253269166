.layout {
  /**
   * On mobile devices we had a problem where 100vh will be larger than the visible
   * height when the URL bar is shown.
   * The useSetFullHeight hook solution is not compatible with this case, because
   * this layout must be able to be more than 100% height.
   * As in this layout the content will hardly be smaller than the viewport, this
   * is not a problem for now, but if that happens it will need to be fixed in the future.
   */
  min-height: 100vh;
}

.header {
  border-bottom: var(--ant-line-width) var(--ant-line-type)
    var(--ant-color-border-secondary);
  padding: 0 var(--ant-padding-md);
}

.footer {
  /* Division line between Footer (white background) and the content well (dim background). */
  border-top: var(--ant-line-width) var(--ant-line-type)
    var(--ant-color-border-secondary);
  line-height: var(--layout-footer-height);
  padding: var(--ant-padding-lg) var(--ant-padding-md);
}
